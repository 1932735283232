import React from "react"
import { Link } from "gatsby"
import aboutUs from "../../assets/images/new-imgs/about-us-2.png"


const AboutUs2 = () => {
  return (

    <section id="about-us-2">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 info-text">
            <h2 className="h3-title mb-5"><strong>SiteBunker</strong> - Complete web hosting solutions </h2>
            <p>The predefined subscriptions don't entirely cover your web hosting needs? Are you also looking for
              technical support to configure your VPS Server? Just contact SiteBunker.</p>
            <p>We are ready for all types of projects, no matter how ambitious the technical part may be. Moreover,
              we’ll support you with extra features and tips & tricks to reach all your sales objectives, whenever your
              website will scale up to the next level.</p>
            <Link to="/contact/" className="view-more-button">CONTACT US NOW</Link>
          </div>
          <div className="col-md-6 position-relative">
            <div className="svg-img">
              <img src={aboutUs} alt="Web Hosting" loading="lazy"/>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutUs2
