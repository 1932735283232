import React from 'react';
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import Banner from "../components/Index/Banner"
import PackagesIntro from "../components/Index/PackagesIntro"
import OurPackages from "../components/Index/OurPackages"
import AboutUs from "../components/Index/AboutUs"
import CheckDomain from "../components/Index/CheckDomain"
import InfoBlocks from "../components/Index/InfoBlocks"
import OurBenefits from "../components/Index/OurBenefits"
import AboutUs2 from "../components/Index/AboutUs2"
import ClientFeedback from "../components/Index/ClientFeedback"
import SEO from "../components/App/SEO"
import CustomerSupportIndex from "../components/Index/CustomerSupportIndex"
import FreeHostingHome from "../components/Index/FreeHostingHome";
import RegisterDomainsHome from "../components/RegisterDomains/RegisterDomainsHome";

const Home = () => {

    return (
        <Layout>
            <SEO title="Performant Web Hosting Solutions | Sitebunker.net"
                 description="Sitebunker.net offers performant web hosting solutions. Advanced Anti-DDos protection. Premium resources and LiteSpeed servers."
            />
            <Navbar/>
            <RegisterDomainsHome/>
            <PackagesIntro/>
            <OurPackages/>
            <AboutUs/>
            <FreeHostingHome
                             title='Choose your Web Hosting Plan and get a premium support'
                             text1='Also, at SiteBunker.net, you have a free WordPress Premium Theme or a free Prestashop Premium Module, on selected web hosting subscriptions with the annual payment. Discover now the effective Premium Web Hosting solutions and see what suits your website’s needs.'
                             text2='The offer is available according to the Terms and Conditions.'/>
            <CustomerSupportIndex/>
            <div className="remove-top-padding">
                <InfoBlocks/>
            </div>
            <div className="grey-background">
                <OurBenefits/>
                <AboutUs2/>
                <ClientFeedback
                    title={"Find out SiteBunker customers' opinion about the provided "}
                    bold={"web hosting services!"}/>
                {/*<Partners />*/}
            </div>
            <Footer/>
            {/*<CookieConsent>This website uses cookies to enhance the user experience.</CookieConsent>*/}
        </Layout>
    )
}

export default Home
