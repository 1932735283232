import React from 'react'


const InfoBlocks = () => {
  return (
    <section  id="info-blocks">
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <div className="info-single-block">
              <p>Since <span className="accent-color">2006</span> <br /> Web hosting Experience</p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="info-single-block">
              <p><span className="accent-color">870+TB</span><br />Safely Hosted</p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="info-single-block">
              <p><span className="accent-color">99,99%</span> <br /> uptime. Reliable hosting</p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="info-single-block">
              <p>More than <span className="accent-color">4500</span> <br /> clients</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default InfoBlocks;
