import React, {Component} from "react"
import {CopyToClipboard} from "react-copy-to-clipboard"
import ReactTooltip from "react-tooltip"
import {Link} from "gatsby"

class PackagesIntro extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isClicked: false, promoCode: "JV89GSYI7K", copied: false
        }
    }

    showPromoCode() {
        this.setState({isClicked: true})
    }

    render() {
        const {isClicked, promoCode} = this.state
        return (<section id="packages-intro">
            <div className="promo-title d-flex justify-content-center">
                <p><span className="accent-bg text-white">ENJOY NOW:</span> Get<span
                    className="second-accent-color ml-2">40%</span> discount for selected web hosting plans. Available for annual payment.
                    {/*<i*/}
                    {/*    className="ml-1 bx bxs-message-rounded-error accent-color"*/}
                    {/*    data-for="enjoy-message"*/}
                    {/*    data-place="top"*/}
                    {/*    data-tip="The free domain .com offer is available for selected web hosting subscriptions with annual payments: Bussines, Enterprise, Start Plus and Enterprise Plus. The gratuity does not apply to the Start package and reseller packages."*/}
                    {/*    data-effect="solid"*/}
                    {/*/>*/}
                    {/*<ReactTooltip backgroundColor="black" type="dark" id="enjoy-message"/>*/}
                </p>
            </div>
            <div className="packages-area container-fluid pt-100">
                <div className="container">
                    <div className="section-title section-title-800">
                        <h2 className="text-white h2-title">FREE WEB HOSTING MIGRATION
                            <i className="ml-1 bx bxs-message-rounded-error accent-color heartbeat"
                               data-for="info-message"
                               data-place="top"
                               data-tip="Offer available only if cPanel access is provided. * Migrations possible only Monday to Friday, 09.00-23.00"
                               data-effect="solid"
                            />
                        </h2>
                        <ReactTooltip backgroundColor="black" type="dark" id="info-message"/>
                        <p className="text-white">Are you looking for a web hosting provider who offers efficient
                            hosting solutions? <br/>
                            At SiteBunker we guarantee free migration and 24/7 support.</p>
                    </div>

                    {/*<div className="buttons-row row mb-5">*/}
                        {/*<div className="col-lg-6 col-md-12 text-right mb-3 mx-auto">*/}
                        {/*    <a className="static-accent-btn-standard d-inline-flex align-items-center w-100 justify-content-center">Save*/}
                        {/*        6 Months / Annual Payment <i className="bx bx-money ml-1"/></a>*/}
                        {/*</div>*/}
                        {/*<div className="col-lg-6 col-md-12 text-left mb-3">*/}
                        {/*    <a className="sm-white-btn-outline d-inline-flex align-items-center w-100 justify-content-center"*/}
                        {/*       onClick={() => this.showPromoCode()}>TRY 1 MONTH FREE<i*/}
                        {/*        className="bx bx-right-arrow-alt ml-1"/></a>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                    {/*<div className={`row ${isClicked ? "d-block" : "d-none"}`}>*/}
                    {/*    <div className="col-12">*/}
                    {/*        <div className="promo text-white text-center mb-5">*/}
                    {/*            Choose your preferred web hosting subscription, add it to cart, select monthly*/}
                    {/*            payment and don't forget*/}
                    {/*            to use the promo code*/}
                    {/*            <span className="promo-code mx-2">*/}
                    {/*                    <CopyToClipboard text={promoCode}>*/}
                    {/*                        <span data-tip="Copied!"*/}
                    {/*                              data-text-color="white"*/}
                    {/*                              data-background-color="#17992C"*/}
                    {/*                              data-place="top"*/}
                    {/*                              data-effect="solid"*/}
                    {/*                              data-event="click"*/}
                    {/*                              data-iscapture={true}*/}
                    {/*                              data-for="promo-code"*/}
                    {/*                        >{promoCode}*/}
                    {/*                            <i className="bx bx-copy-alt"/>*/}
                    {/*                        </span>*/}
                    {/*                    </CopyToClipboard>*/}
                    {/*                    <ReactTooltip id="promo-code"/>*/}
                    {/*                </span>before finishing the order. Enjoy your 1 month for FREE!*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>
        </section>)
    }
}

export default PackagesIntro
