import React from "react"
import {Link} from "gatsby"
import ReactTooltip from "react-tooltip"

import '/src/assets/css/Homepage/our-packages.css'

import webSSDImage from "../../assets/images/home-img/web.png"
import vpsImage from "../../assets/images/home-img/vps-server-icon.png"
import prestashopImage from "../../assets/images/home-img/presta.png"
import wordpressImage from "../../assets/images/home-img/wordpress.png"

import redisImage from "../../assets/images/mini-imgs/redis-icon.png"
import redisGreyImage from "../../assets/images/mini-imgs/redis-grey.png"


const OurPackages = () => {
    return (
        <section id="home-our-packages">

            <ReactTooltip backgroundColor="black" type="dark" id="tooltip-homepage"/>

            <div className="row packages-row">

                <div className="col-lg-3 col-md-6 mb-md-3 single-package position-relative">
                    <div className="package-block">
                        <div className="row title-and-price align-items-center">
                            <div className="col-md-12">
                                <h3>SSD Web <br/>
                                    Hosting</h3>
                            </div>
                            <div className="col-md-12">
                                <p className="align-items-center mt-3">
                                    <span>from </span>
                                    <span className='price-tag'>$2.90</span>
                                    <span>/month</span>
                                </p>
                            </div>

                            <div className='col-md-12'>
                                <span className="align-items-center mt-3 red-promo">
                                    Save 40% on annual payment
                                </span>
                            </div>
                        </div>


                        <Link to="/web-ssd-hosting/" className="more-package-btn">Check packages</Link>


                        <div className="package-img">
                            <img src={webSSDImage} alt="Web SSD" lazy="true"/>
                        </div>


                    </div>
                    <div className="home-package-feature">
                        <ul>
                            <li><i className="flaticon-tick"/>LiteSpeed Webserver<i
                                className="bx bx-info-circle ml-1 font-weight-light"
                                data-for="tooltip-homepage" data-place="top"
                                data-tip="LiteSpeed ensures the fast loading of your website"
                                data-effect="solid"/></li>

                            <li><i className="flaticon-tick"/>DDoS Protection<i
                                className="bx bx-info-circle ml-1 font-weight-light"
                                data-for="tooltip-homepage" data-place="top"
                                data-tip="Through GTS(Arbor) & Voxility"
                                data-effect="solid"/></li>

                            <li><i className="flaticon-tick"/>NodeJS Selector</li>
                        </ul>

                    </div>
                </div>

                <div className="col-lg-3 col-md-6 mb-md-3 single-package position-relative">
                    <div className="package-block">
                        <div className="row title-and-price align-items-center">
                            <div className="col-md-12">
                                <h3>WordPress SSD <br/>Hosting</h3>
                            </div>
                            <div className="col-md-12">
                                <p className="align-items-center mt-3">
                                    <span>from </span>
                                    <span className='price-tag'>$7.90</span>
                                    <span>/month</span>
                                </p>
                            </div>
                            <div className='col-md-12'>
                                <span className="align-items-center mt-3 red-promo">
                                    Save 35% on annual payment
                                </span>
                            </div>
                        </div>


                        <Link to="/wordpress-ssd-hosting/" className="more-package-btn">Check packages</Link>


                        <div className="package-img">
                            <img src={wordpressImage} alt="Wordpress" lazy="true"/>
                        </div>


                    </div>
                    <div className="home-package-feature">
                        <ul>
                            <li><i className="flaticon-tick"/> <span className='free-featured'>FREE</span> Premium Wordpress Theme<a
                                className="bx bx-info-circle ml-1 font-weight-light"
                                data-for="tooltip-homepage" data-place="top"
                                href={"/claim-now-your-free-premium-word-press-theme-and-web-hosting-subscriptions/"}
                                data-tip="Available for annual payment. Click for details."
                                data-effect="solid"/></li>

                            <li><i className="flaticon-tick"/>LiteSpeed WP Cache<i
                                className="bx bx-info-circle ml-1 font-weight-light"
                                data-for="tooltip-homepage" data-place="top"
                                data-tip="LiteSpeed ensures the fast loading of your website"
                                data-effect="solid"/></li>

                            <li>
                                <img className="redis-icon" src={redisImage} alt="Redis Icon" lazy="true"/>
                                Redis Cache
                            </li>
                        </ul>

                    </div>
                </div>

                <div className="col-lg-3 col-md-6 mb-md-3 single-package position-relative">
                    <div className="package-block">
                        <div className="row title-and-price align-items-center">
                            <div className="col-md-12">
                                <h3>PrestaShop SSD <br/>Hosting</h3>
                            </div>
                            <div className="col-md-12">
                                <p className="align-items-center mt-3">
                                    <span>from </span>
                                    <span className='price-tag'>$13.90</span>
                                    <span>/month</span>
                                </p>
                            </div>
                            <div className='col-md-12'>
                                <span className="align-items-center mt-3 red-promo">
                                    Save 30% on annual payment
                                </span>
                            </div>
                        </div>


                        <Link to="/prestashop-ssd-hosting/" className="more-package-btn">Check packages</Link>


                        <div className="package-img">
                            <img src={prestashopImage} alt="Prestashop" lazy="true"/>
                        </div>


                    </div>
                    <div className="home-package-feature">
                        <ul>

                            <li><i className="flaticon-tick"/> <span className='free-featured'>FREE</span> Premium Prestashop Module<a
                                    className="bx bx-info-circle ml-1 font-weight-light"
                                    data-for="tooltip-homepage" data-place="top"
                                    data-tip="Available for annual payment. Click for details."
                                    href={"/choose-your-free-presta-shop-modules-together-with-the-perfect-web-hosting"}
                                    data-effect="solid"/></li>

                            <li><i className="flaticon-tick"/>LiteSpeed PrestaShop Cache<i
                                className="bx bx-info-circle ml-1 font-weight-light"
                                data-for="tooltip-homepage" data-place="top"
                                data-tip="LiteSpeed ensures the fast loading of your website"
                                data-effect="solid"/></li>

                            <li><i className="flaticon-tick"/>Support 24/7</li>
                        </ul>

                    </div>
                </div>

                <div className="col-lg-3 col-md-6 mb-md-3 single-package position-relative">
                    <div className="package-block">
                        <div className="row title-and-price align-items-center">
                            <div className="col-md-12">
                                <h3>VPS <br/>Servers</h3>
                            </div>
                            <div className="col-md-12">
                                <p className="align-items-center mt-3">
                                    <span>from </span>
                                    <span className='price-tag'>$4.95</span>
                                    <span>/month</span>
                                </p>
                            </div>
                            <div className='col-md-12'>
                                <span className="align-items-center mt-3 red-promo">
                                    Save 20% on annual payment
                                </span>
                            </div>
                        </div>


                        <Link to="/vps-servers/" className="more-package-btn">Check packages</Link>


                        <div className="package-img">
                            <img src={vpsImage} alt="Prestashop" lazy="true"/>
                        </div>


                    </div>
                    <div className="home-package-feature">
                        <ul>
                            <li><i className="flaticon-tick"/>NVMe SSD Storage</li>

                            <li><i className="flaticon-tick"/>Traffic Unlimited</li>

                            <li><i className="flaticon-tick"/>DDoS Protection</li>
                        </ul>

                    </div>
                </div>

            </div>

        </section>
    )
}

export default OurPackages
