import React from "react"
import techImg from "../../assets/images/new-imgs/technical-assistance.png"
import { Link } from "gatsby"

const CustomerSupportIndex = () => {


  return (
    <section id="customer-support">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 position-relative">
            <div className="svg-img">
              <img src={techImg} alt="Web Hosting" loading="lazy"/>
            </div>
          </div>
          <div className="col-md-6 info-text">
            <h2 className="mb-5 h3-title">24/7 CUSTOMER SUPPORT</h2>
            <p>SiteBunker’s Customer Support Team is available to assist you in any situation.</p>
            <p className="mb-5 "> We reply quickly with solutions for any technical challenges you might encounter. You
              can get in touch with us very easily, at any time, even for a simple question.</p>
            <Link to="/contact/" className="view-more-button">TEST OUR SUPPORT</Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CustomerSupportIndex;
