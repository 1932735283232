import React from "react"
import aboutUsImg from "../../assets/images/new-imgs/site-home2-2.png"


const AboutUs = () => {
  return (
    <section id="about-us">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 about-us-text">
            <h2 className="mb-5 h3-title">Your online success always starts with a performant web hosting! </h2>
            <p>At SiteBunker we understand how important it is to rely on your hosting solution and how this impacts
              your online success.</p>
            <p>Regardless of the web hosting subscription you choose, we offer you efficient solutions to support your
              activity.</p>
          </div>
          <div className="col-md-6 position-relative">
            <div className="svg-img">
              <img src={aboutUsImg} alt="Web Hosting" loading="lazy" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutUs
