import React from "react"
import antivirusIcon from "../../assets/images/sistem-antivirus.png"
import notifIcon from "../../assets/images/sistem-notificari.png"
import servIcon from "../../assets/images/servicii-web.png"
import customerIcon from "../../assets/images/customer-support-icon.png"
import { Link } from "gatsby"

const OurBenefits = () => {
  return (
    <section id="our-benefits">
      <div className="container">
        <div className="title-row pb-70">
          <h2 className="h3-title text-center mb-4">Improve your web hosting experience with <strong>SiteBunker</strong>
          </h2>
          <h3 className="h3-title text-center"><em>Here is why you should choose us:</em></h3>
        </div>
        <div className="row">
          <div className="col-md-6 benefit-col">
            <div className="row">
              <div className="col-md-12 col-lg-3">
                <img src={antivirusIcon} loading="lazy" alt="" />
              </div>
              <div className="col-md-12 col-lg-9">
                <h4>PERFORMANT SECURITY</h4>
                <p>We offer more than just web hosting. We are also constantly focused on protection. SiteBunker’s
                  security solutions, including the ones for DDoS attacks, are always being improved. </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 benefit-col">
            <div className="row">
              <div className="col-md-12 col-lg-3">
                <img src={notifIcon} loading="lazy" alt="" />
              </div>
              <div className="col-md-12 col-lg-9">
                <h4>ALWAYS INFORMED</h4>
                <p>You will constantly be up to date with whatever interferes with your site. Our real-time notification
                  system will help you check the <Link to="/status/">status</Link> of your web hosting services.</p>
              </div>
            </div>
          </div>
          <div className="col-md-6 benefit-col">
            <div className="row">
              <div className="col-md-12 col-lg-3">
                <img src={servIcon} loading="lazy" alt="" />
              </div>
              <div className="col-md-12 col-lg-9">
                <h4>THE RIGHT TEAM</h4>
                <p>Your website has reached the right place. We are IT experts passionate about proficient web hosting
                  services, ready to find effective solutions for your online presence.</p>
              </div>
            </div>
          </div>
          <div className="col-md-6 benefit-col">
            <div className="row">
              <div className="col-md-12 col-lg-3">
                <img src={customerIcon} loading="lazy" alt="" />
              </div>
              <div className="col-md-12 col-lg-9">
                <h4>24/7 AVAILABILITY</h4>
                <p>SiteBunker’s Support Team is ready to solve any technical challenge you face, at any time, 365 days
                  per year. Contact us and we will respond in a very short time.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default OurBenefits
