import React, { useState } from "react"
import "./Feedback.style.css"


const Feedback = (props) => {

  const [arata, setArata] = useState(props.stare)

  const inchideHandler = () => {
    setArata(false)
    props.inchide(arata)
  }

  if (props.available === "available") {
    return (
      <div className="alert alert-success d-flex flex-grow-1 justify-content-between align-items-center" role="alert">

        <p style={{ color: "black" }} className="m-0 text-left">The domain is available.
          <a className="font-weight-bold ml-1"
             href={`https://client.sitebunker.net/cart.php?a=add&domain=register&domains[]=${props.domeniu}&1${props.domeniu}=1&language=english&currency=3`}
             target="_blank">Order now!</a>
        </p>

        <span onClick={inchideHandler} className="close important">x</span>
      </div>
    )
  }
  if (props.available === "unavailable") {
    return (
      <>
          {props.type === "Transfer" ?
              <div className="alert alert-success d-flex flex-grow-1 justify-content-between align-items-center"
                   role="alert">
                  <p className='m-0 text-left' style={{color: 'black'}}>Domain can be transferred.
                      <a className='font-weight-bold ml-1'
                         href={`https://client.sitebunker.net/cart.php?a=add&domain=transfer&domains[]=${props.domeniu}&1${props.domeniu}=1&language=english&currency=3`}
                         target="_blank">Transfer!
                      </a>
                  </p>
                  <span onClick={inchideHandler} className='close important'>x</span>
              </div>
              :
              <div
                  className="alert alert-danger d-flex flex-grow-1 justify-content-between align-items-center"
                  role="alert">
                  <p className='m-0 text-left' style={{color: 'black'}}>Domain not available</p>
                  <span onClick={inchideHandler} className='close important'>x</span>
              </div>
          }

      </>
    )
  } else {
    return null
  }
}

export default Feedback
