import React from "react"

import "../../assets/css/Homepage/homepage.css"
import hostingImg from "../../assets/images/hosting-discount.png";

const FreeHostingHome = ({title, text1, text2}) => {
    return (
        <section id="free-hosting-home">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 position-relative">
                        <img src={hostingImg} alt="Web Hosting" loading="lazy"/>
                    </div>
                    <div className="col-md-6 info-text">
                        <h2 className="mb-5 h3-title font-weight-bolder">{title}</h2>
                        <p>{text1}</p>
                        <p>{text2}</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FreeHostingHome
